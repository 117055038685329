.quill-send-btn {
  bottom: 1%;
  right: 1%;
}

.btnStyle {
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 30px;
  outline: none;
  border: none;
  background: rgb(247, 232, 245);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  bottom: 0px !important;
  position: relative;
}
.Wirisformula {
  width: 500px !important;
  max-width: 1500px !important;
}

.tox-statusbar__branding {
  display: none;
}

.container {
  height: 250px;
}

.edit-quill-send-btn {
  bottom: 5px;
  right: 5px;
  z-index: 100;
}

.tox .tox-editor-container {
  margin-bottom: 65px;
}

.tox-statusbar__path-item {
  display: none;
}

.mobile-btn-wrapper {
  width: 95%;
  padding-left: 10px;
  padding-right: 0px;
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: right;
  gap: 20px;
}

.tiny-custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  max-width: 190px;
  height: 50px;
  background: var(--violet, #624ba2);
  border-radius: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.tiny-custom-button2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  max-width: 190px;
  height: 50px;
  color: #624ba2;
  border-radius: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.tiny-custom-button2:hover {
  background: var(--violet, #624ba2);
  color: #ffff;
}
